<template>
  <div class="procuct-cont">
    <div class="product-box">
      <div class="product-title">Pick Up List</div>
      <div class="product-top">
        <div class="time-title">Pick Up Time</div>
        <a-space>
          <div class="search">
            <a-config-provider :locale="enUS">
              <!-- <a-range-picker format="MM/DD/YYYY HH:mm" showTime /> -->
              <a-range-picker v-model="searchtime" style="width:100%;" :placeholder="['From', 'To']"
                :format="dateFormate" />
            </a-config-provider>
          </div>
          <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
            @ok="confirmReset" class="warnPop">
            <a-button shape="round">Reset</a-button>
          </a-popconfirm>
          <a-button type="primary" shape="round" @click="searchVal">Search</a-button>
        </a-space>
      </div>
      <!-- 导航 -->
      <div class="menu-select">
        <div class="nav-cont">
          <div class="statType">Pick up Orders</div>
          <div class="topoperate">
            <a-menu mode="horizontal" @menu-item-click="clickMenu" :selected-keys="[navselect]">
              <a-menu-item :key="val" v-for="val, k in navList">{{ k }}</a-menu-item>
            </a-menu>
          </div>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table-box">
      <a-config-provider :locale="enUS">
        <a-table :data="listData.value" :bordered="false" :pagination="false" :scroll="{ y: tableHeight.value }"
          :loading="loading">
          <template #empty>
            <div v-if="listData.value.length === 0 && loading === false"
              :style="{ height: tableHeight.value - 35 + 'px' }" class="empty-cont">
              <img src="../../../assets/images/empty/listempty.svg" alt="">
              <div>No results found</div>
            </div>
            <div v-if="loading === true" :style="{ height: tableHeight.value - 35 + 'px' }" class="skeleton-box">
            </div>
          </template>
          <template #columns>
            <a-table-column title="Pick Up Time" data-index="pickuptime" :width="220"></a-table-column>
            <a-table-column title="Total Count" data-index="totalcount" :width="180"></a-table-column>
            <a-table-column data-index="totalvolume" :width="220">
              <template #title>
                Total Volume(Ft<sup>3</sup>)
              </template>
              <template #cell="{ record }">
                {{ record.totalvolume }}
              </template>
            </a-table-column>
            <a-table-column title="Total Weight(Lb)" data-index="totalweight" :width="180"></a-table-column>
            <a-table-column title="Pick Up Address" data-index="pickupaddress" :width="220">
              <template #cell="{ record }">
                <div>{{ record.street1 }}</div>
                <div v-if="record.street2">{{ record.street2 }}</div>
                <div>{{ record.city }},{{ record.state }},{{ record.zip }},{{ record.country }}</div>
              </template>
            </a-table-column>
            <a-table-column v-if="navselect === 1" title="Action" data-index="action" :width="75">
              <template #cell="{ record }">
                <a-space>
                  <a-popconfirm content="Are you sure you want to cancel?" position="bottom" ok-text="Cancel"
                    cancel-text="Back" class="warnPop" style="width:273px;" @ok="cancelSingle(record.key)" type="warning">
                    <a-tooltip content="Cancel">
                      <a-button shape="circle">
                        <icon-stop />
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>
                </a-space>
              </template>
            </a-table-column>
          </template>
          <!-- <template #action="{ record }">
            <a-space>
              <a-popconfirm content="Are you sure you want to delete this product?" position="bottom"
                ok-text="Comfirm Delete" cancel-text="Cancel" class="deletePop" style="width:273px;"
                @ok="deleteSingle(record.key)" type="warning">
                <a-button shape="circle">
                  <icon-delete />
                </a-button>
              </a-popconfirm>
            </a-space>
          </template> -->
        </a-table>
      </a-config-provider>
    </div>
    <div class="add-page">
      <a-space>
        <span>Total : {{ total }}</span>
        <a-button :disabled="currentpage == 1" type="text" @click="clickMinus">
          <icon-left />
        </a-button>
        <span class="currentpage">{{ currentpage }}</span>
        <div>/</div>
        <div>{{ totalPages }}</div>
        <a-button type="text" :disabled="currentpage == totalPages || totalPages == 0" @click="clickAdd">
          <icon-right />
        </a-button>
        <a-select :style="{ width: '134px' }" v-model="pageSize" @change="selectPage">
          <a-option :value="20">20 / Page</a-option>
          <a-option :value="50">50 / Page</a-option>
          <a-option :value="100">100 / Page</a-option>
          <a-option :value="200">200 / Page</a-option>
        </a-select>
        <!-- <a-button shape="circle" id="setBtn">
          <icon-settings />
        </a-button> -->
      </a-space>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { Message, Notification } from '@arco-design/web-vue'
import {
  IconStop, IconRight, IconLeft
} from '@arco-design/web-vue/es/icon'
import { pickupList, pickupCancel } from '@/api/pickup.js'
import local from '@/utils/local'
const loading = ref(false)
const searchtime = ref(['', ''])
// 导航
const navList = {
  'Awaiting  Picking Up': 1,
  'Picked Up': 2,
  'Canceled': 3 // eslint-disable-line quote-props
}
const navselect = ref(1)
// 点击导航
const clickMenu = (val) => {
  console.log(val)
  navselect.value = val
  currentpage.value = 1
  getList()
}
// regular表格配置
// const listColumns = ref([
//   {
//     title: 'Pick Up Time',
//     dataIndex: 'pickuptime',
//     slotName: 'pickuptime',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 220
//   },
//   {
//     title: 'Total Count',
//     dataIndex: 'totalcount',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 150
//   },
//   {
//     title: 'Total Volume(Ft3)',
//     dataIndex: 'totalvolume',
//     slotName: 'totalvolume',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 180
//   },
//   {
//     title: 'Total Weight(Lb)',
//     dataIndex: 'totalweight',
//     slotName: 'totalweight',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 180
//   },
//   {
//     title: 'Pick Up Address',
//     dataIndex: 'pickupaddress',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 200
//   },
//   {
//     title: 'Action',
//     dataIndex: 'action',
//     // fixed: 'right',
//     slotName: 'action',
//     width: 75
//   }
// ])
// const listColumns1 = ref([
//   {
//     title: 'Pick Up Time',
//     dataIndex: 'pickuptime',
//     slotName: 'pickuptime',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 220
//   },
//   {
//     title: 'Total Count',
//     dataIndex: 'totalcount',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 150
//   },
//   {
//     title: 'Total Volume(Ft3)',
//     dataIndex: 'totalvolume',
//     slotName: 'totalvolume',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 180
//   },
//   {
//     title: 'Total Weight(Lb)',
//     dataIndex: 'totalweight',
//     slotName: 'totalweight',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 180
//   },
//   {
//     title: 'Pick Up Address',
//     dataIndex: 'pickupaddress',
//     sortable: { sortDirections: ['ascend', 'descend'] },
//     width: 200
//   }
// ])
// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
const tableWidth = ref(0)
tableHeight.value = document.body.clientHeight - 390
tableWidth.value = document.body.clientWidth - 50
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableWidth.value = document.body.clientWidth - 50
    tableHeight.value = document.body.clientHeight - 390
  }
})
// 确认重置
const confirmReset = () => {
  console.log(searchtime.value)
  if (searchtime.value[0] === '' && searchtime.value[1] === '') {
    Message.warning('No input content.')
  } else {
    searchtime.value = ['', '']
    currentpage.value = 1
    getList()
  }
}
// 表格数据
let listData = reactive({ value: [] })
// 分页配置
const currentpage = ref(1)
const total = ref(0)
const totalPages = ref(0)
const pageSize = ref(20)
// 获取列表
const getList = async () => {
  loading.value = true
  const msg = await pickupList({
    page: currentpage.value,
    status: navselect.value,
    start_date: searchtime.value[0],
    end_date: searchtime.value[1],
    pageCount: pageSize.value
  })
  console.log(msg)
  if (msg.code === 0) {
    loading.value = false
    searchtime.value = ['', '']
    currentpage.value = msg.data.current_page
    total.value = msg.data.count
    totalPages.value = msg.data.page_count
    let info = msg.data.data
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({
        key: info[i].id, pickuptime: info[i].appointment_date + ' ' + info[i].appointment_time, totalcount: info[i].num, totalvolume: info[i].volume, totalweight: info[i].weight, street1: info[i].street1, street2: info[i].street2, zip: info[i].zip, city: info[i].city, state: info[i].state, country: info[i].country
      })
    }
    listData.value = currentData
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 初始化渲染
getList()

// 点击页数减
const clickMinus = () => {
  currentpage.value--
  getList()
}
// 点击页数加
const clickAdd = () => {
  currentpage.value++
  getList()
}
// 设置每页显示个数
const selectPage = (val) => {
  pageSize.value = val
  getList()
}

// 搜索
const searchVal = () => {
  currentpage.value = 1
  getList()
}

// 删除单个
const cancelSingle = async (k) => {
  const msg = await pickupCancel({
    ids: [k]
  })
  console.log(msg)
  if (msg.code === 0) {
    Notification.success('Canceled.')
    getList()
  } else {
    Notification.error(msg.message)
  }
}
// 时间设置--------------------------------------------------
let dateFormate = ref('MM/DD/YYYY')
let timeFormate = JSON.parse(local.get('userInfo')).date_format
// console.log(timeFormate)
if (timeFormate === 1) {
  dateFormate.value = 'MM/DD/YYYY'
} else if (timeFormate === 2) {
  dateFormate.value = 'DD/MM/YYYY'
} else if (timeFormate === 3) {
  dateFormate.value = 'YYYY/MM/DD'
}
</script>
<style scoped lang="less">
.empty {
  color: rgb(var(--danger-6));
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.noarea {
  color: var(--color-text-4);
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.empty-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.product-box {
  padding: 0 24px;

  .product-title {
    font-weight: 500;
    font-size: 20px;
    color: var(--color-text-1);
    margin: 18px 0;
  }

  .time-title {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-2);
    padding-bottom: 8px;
  }
}

// 导航
.menu-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  margin-top: 24px;

  .nav-cont {
    display: flex;
    align-items: center;

    .statType {
      font-weight: 500;
      font-size: 16px;
      padding-right: 20px;
    }
  }
}

.topoperate {
  padding: 24px 0;
  width: 700px;

  :deep(.arco-menu-light .arco-menu-item.arco-menu-selected) {
    background-color: var(--color-fill-2);
  }

  :deep(.arco-menu-selected-label) {
    background-color: transparent;
  }
}

.table-box {
  padding: 0 24px;
}

// 分页
.add-page {
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 0 0;

  .arco-btn-text.arco-btn-disabled,
  .arco-btn-text[type='button'].arco-btn-disabled {
    color: var(--color-text-4);
  }

  .arco-btn-text,
  .arco-btn-text[type='button'] {
    color: var(--color-text-2);
  }
}

.currentpage {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  padding: 1px 8px;
  display: inline-block;
  box-sizing: border-box;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}

.deletePop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}
</style>
